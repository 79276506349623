import { useMemo, useState } from "react";
import { useMenuButton, useMenuState } from "reakit";
import { SelectProps } from "./types";
import v4 from "cuid";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { MenuList } from "components/miloDesignSystem/atoms/menu";
import { MenuItem } from "components/miloDesignSystem/atoms/menu/types";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import styles from "./Select.module.css";
import { cx } from "utilities";
import { ClickOutsideHandler } from "components/utils";

export const Select = ({
  textFieldProps,
  selected,
  items,
  onChange,
  label,
  className,
  theme = "light",
  disabled,
}: SelectProps) => {
  const menu = useMenuState({});
  const menuButton = useMenuButton(menu);
  const [searchValue, setSearchValue] = useState("");
  const clickOutsideIgnoreClass = useMemo(() => `click-outside-select-omit-${v4()}`, []);
  const selectedItem = items.find(item => String(item.value) === String(selected));

  console.log(items);
  const filteredMenuItems = items.filter(item =>
    item.text.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
  );

  const menuItems = filteredMenuItems.map(
    menuItem =>
      ({
        ...menuItem,
        options: {
          ...menuItem.options,
          className: selectedItem?.value === menuItem.value ? styles.selected : "",
        },

        onClick: () => {
          setSearchValue("");
          onChange(menuItem.value);
          menu.hide();
        },
      } as MenuItem),
  );

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = e => {
    if (disabled) return;
    e.stopPropagation();
    menu.setVisible(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!menu.visible) {
      menu.setVisible(true);
    }
    switch (event.key) {
      case "Up":
      case "ArrowUp":
        event.preventDefault();
        menu.last();
        return;
      case "Down":
      case "ArrowDown":
        event.preventDefault();
        menu.first();
        return;
      case "Enter":
        event.preventDefault();

        if (menuItems.length) {
          menuItems[0]?.onClick();
        }
        return;
      case "Esc":
      case "Escape":
        event.preventDefault();
        setSearchValue("");
        menu.hide();
        return;
    }
  };

  return (
    <ClickOutsideHandler
      onClickOutside={() => {
        menu.setVisible(false);
        setSearchValue("");
      }}
      outsideClickIgnoreClass={clickOutsideIgnoreClass}
    >
      <div
        ref={menuButton.ref}
        onClick={() => {
          if (!menu.visible && !disabled) {
            menu.setVisible(true);
            menu.first();
          }
        }}
      >
        <TextField
          disabled={disabled}
          inputClassName={cx(
            { [styles[`selectedPlaceholder-${theme}`]]: Boolean(selectedItem) && !disabled },
            clickOutsideIgnoreClass,
          )}
          containerClassName={cx(className, clickOutsideIgnoreClass)}
          theme={theme}
          endIcon={MdiKeyboardArrowDown}
          label={label}
          value={searchValue}
          placeholder={selectedItem?.text || "Szukaj..."}
          size="small"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          {...textFieldProps}
        />

        <MenuList
          searchValue={searchValue}
          className={clickOutsideIgnoreClass}
          hideOnClickOutside={false}
          menuItems={menuItems}
          menuState={menu}
        />
      </div>
    </ClickOutsideHandler>
  );
};
